.b-brands_alphabet {
	padding-bottom: rh(12);

	@include media(sm) {
		@include g-accordion-styles;
	}

	&-section {
		@include media(md-up) {
			margin-top: rh(10);
		}

		@include media(sm) {
			@include g-accordion-styles(_item);

			margin-top: 0;
		}
	}

	// border-top and margin-top to fix the anchor issue with fixed header
	&-letter {
		background-clip: padding-box;
		border-top: 170px solid transparent;
		color: $color-red;
		font-size: 24px;
		font-weight: bold;
		margin-top: -170px;

		@include media(md-up) {
			margin-bottom: rh(6);
		}

		@include media(sm) {
			border-bottom: 0;
			border-top: 73px solid transparent;
			margin-top: -73px;
			padding: rh(4 0 3);
		}

		&:not(.m-empty) {
			@include media(md-up) {
				border-bottom: 1px solid $color-brand94;
				padding-bottom: rh(5);
			}
		}

		&.m-ex {
			@include media(sm) {
				@include g-accordion-styles(_control);
			}

			&::after {
				@include media(sm) {
					color: $color-brand;
					font-size: 24px;
					right: 0;
				}
			}
		}
	}

	&-letter.m-ex.m-expanded {
		@include media(sm) {
			@include g-accordion-styles(_control_expanded);

			border-bottom: 1px solid $color-brand94;
		}
	}

	&-list {
		column-gap: rh(10);
		padding-bottom: rh(2);

		@include media(lg-up) {
			column-count: 4;
		}

		@include media(md) {
			column-count: 3;
		}

		@include media(sm) {
			@include g-accordion-styles(_content);

			border-bottom: 1px solid $color-brand94;
			column-count: 1;
		}
	}

	&-list.m-expanded {
		@include media(sm) {
			@include g-accordion-styles(_content_expanded);

			border-bottom: 0;
			padding: rh(7 0 2);
		}
	}

	&-list_item {
		@include media(md-up) {
			margin-bottom: rh(4);
		}

		@include media(sm) {
			margin-bottom: rh(5);
		}
	}

	&-link {
		color: $color-text;
		font-size: 16px;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}
