.l-plp {
	display: flex;
	margin-top: rh(8);

	@include media(sm) {
		margin-top: rh(6);
	}

	&-main {
		width: 100%;

		.h-country_selector {
			display: none;
		}
	}

	&-refinements {
		@include media(md-down) {
			@include g-dialog-backdrop;

			margin: 0;
			max-width: unset;
			min-width: unset;

			&.is-active {
				overflow: hidden;
			}
		}

		@include media(md-down) {
			min-width: 280px;
		}

		&.m-hidden {
			display: none;
		}
	}

	&-refinements_panel {
		transition: top $motion-ease;

		@include media(md-down) {
			background-color: $color-white;
			bottom: 0;
			left: 0;
			margin: 0;
			max-width: 330px;
			overflow-x: hidden;
			overflow-y: auto;
			position: fixed;
			top: 0;
			transform: translateX(-100%);
			transition: transform $motion-ease-panels;
			width: 100%;
			z-index: z(modal);

			&.is-open {
				box-shadow: $depth-3;
				transform: translateX(0);
			}

			&::-webkit-scrollbar {
				width: 10px !important; /* stylelint-disable-line */
			}
		}

		@include media(sm) {
			max-width: 280px;
			min-width: initial;
		}

		&.is-sticky {
			max-width: 330px;
			position: fixed;
			top: 0;
			width: 65%;
			z-index: 2;
		}
	}
}
