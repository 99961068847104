@mixin g-accordion-styles(
	$_: root,
	$mod: default
) {
	@if $_ == root {
		border-top: 1px solid $color-brand88;
	}

	@if $_ == _item {
		border-bottom: 1px solid $color-brand88;
	}

	@if $_ == _control {
		@if $mod == default {
			appearance: none;
			background: transparent;
			border: none;
			color: $color-brand;
			cursor: pointer;
			display: flex;
			font-family: inherit;
			position: relative;
			text-align: left;
			text-decoration: none;
			text-transform: uppercase;
			user-select: none;
			width: 100%;
		}

		@if $mod == full {
			padding: rh(4 5 4 0);

			&::after {
				right: 0;
			}
		}
	}

	@if $_ == _control_expanded {
		.b-icon_plus-line_1 {
			transform: rotate(180deg);
		}

		.b-icon_plus-line_2 {
			transform: rotate(270deg);
		}
	}

	@if $_ == _content {
		@if $mod == default {
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			padding: rh(0 4);
			position: relative;
			visibility: hidden;
		}

		@if $mod == full {
			padding-left: 0;
			padding-right: 0;
		}
	}

	@if $_ == _content_expanded {
		max-height: 3000px;
		opacity: 1;
		padding: rh(2 4 7);
		transition: $motion-ease;
		transition-property: max-height, opacity, visibility, padding;
		visibility: visible;
	}
}
