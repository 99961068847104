$color-arrows: url-color-legacy($color-blue);

/* stylelint-disable */
.b-featured {
	margin-bottom: rh(30);

	@include media(md-down) {
		margin-bottom: rh(20);
	}

	.b-categories_list-featured & {
		margin-bottom: rh(10);
	}

	&-carousel {
		@include g-carousel;

		position: relative;
	}

	&-carousel_shop-link {
		color: $color-black;
		font-size: 18px;
	}

	&-carousel_title {
		margin-bottom: 20px;

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-carousel_category {
		.slick-list {
			margin: 0 -10px;
			padding-left: 0 !important;
		}

		.slick-slide {
			border: 1px solid $color-grey77;
			border-radius: 3px;
			margin: 0 10px;
		}
	}

	&-tile {
		@include g-carousel(_slide);

		max-width: 280px;

		&_category-title {
			color: $color-black;
			display: inline-block;
			padding: 10px;
		}

		.b-product_shipping_info,
		.b-product_tile-promo {
			display: none;
		}

		.b-product_tile-title {
			font-weight: normal;
			text-align: left;
		}

		.b-product_tile-ratings {
			display: flex;
			margin-bottom: 10px;

			img {
				display: inline-flex;
			}
		}

		.b-price {
			justify-content: flex-start;
		}

		.b-price-label {
			display: block;
			font-size: 16px;
			margin-right: 8px;

			&.m-secondary,
			&.m-regular {
				display: none;
			}
		}

		.b-price-sales {
			flex-direction: row;
		}

		.b-price-old {
			flex-direction: row;
			margin-right: 10px;
		}

		.b-product_tile-price {
			height: 30px;
			margin: 0;

			@include media(sm) {
				height: 45px;
			}
		}
	}

	.slick-slide {
		.b-categories_list-featured & {
			border: 1px solid $color-grey77;
			border-radius: 3px;
			margin: 0 10px;
		}
	}

	.slick-list {
		padding: 13px 0;

		.b-categories_list-featured & {
			margin: 0 -10px;
			padding-left: 0 !important;
		}
	}
}
