.b-product_shipping_info {
	font-size: 14px;

	&-item {
		align-items: center;
		display: flex;
		margin-bottom: rh(2);
		text-align: left;

		&::before {
			background: url('../../img/close.svg') $color-grey77 center center no-repeat;
			background-size: 10px;
			border-radius: 50%;
			color: $color-white;
			content: '';
			display: block;
			margin-right: rh(3);
			min-height: 20px;
			min-width: 20px;
			overflow: hidden;
			text-indent: -100px;
		}

		&.m-available {
			&::before {
				background-color: transparent;
				background-image: url('../../img/checked.svg');
				background-size: 20px;
				content: '';

				.b-theme_hmv & {
					background-image: url('../../img/checked-hmv.svg');
				}
			}
		}
	}
}
