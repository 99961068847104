.b-categories_list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: rh(7) -1.1%;

	&-item {
		border: 1px solid $color-brand94;
		border-radius: $global-radius;
		color: $color-text;
		display: block;
		font-weight: bold;
		margin: 3% 1.1%;
		padding: rh(5 8);
		position: relative;
		text-align: center;
		text-decoration: none;
		width: 160px;

		@include media(sm) {
			width: 45%;
		}

		&::after {
			border-radius: $global-radius;
			box-shadow: $depth-3;
			content: '';
			inset: 0;
			opacity: 0;
			position: absolute;
			transition: opacity $motion-ease;
		}

		&:hover {
			border-color: $color-brand94;
			text-decoration: none;

			&::after {
				opacity: 1;
			}
		}

		&:active {
			border-color: $color-brand;

			&::after {
				opacity: 0;
				transition: none;
			}
		}

		&:focus {
			border-color: $color-brand;
		}
	}

	figure {
		padding: 0;
	}

	h2 {
		margin: rh(2 0);
		min-height: 3.6em;
		word-wrap: break-word;
	}

	img {
		background-color: $color-brand97;
		display: block;
		height: auto;
		margin: auto;
		max-height: 100px;
		max-width: 100px;
		width: 100%;
	}
}
