@mixin g-dialog-backdrop() {
	align-items: center;
	display: flex;
	inset: 0;
	position: fixed;
	transition: visibility $motion-ease-panels;
	visibility: hidden;
	z-index: z(modal);

	&.is-active {
		@include prevent-scroll-chain;

		overflow-y: scroll;
		visibility: visible;

		@include media(lg-up) {
			&.new-layout {
				overflow: hidden;
			}
		}
	}

	/* the purpose to make it separate is to handle different animation */
	// negative position is for fix overlay sliding on mobile fast swipe
	&::after {
		background-color: transparent;
		content: '';
		inset: -200px 0;
		opacity: 0;
		position: fixed;
		transition: $motion-ease-panels;
		transition-property: opacity, background-color;
		z-index: 1;
	}

	// stylelint-disable-line block-no-empty
	&.is-active.is-top-dialog::after {
		background-color: rgb(0 0 0 / 50%);
		opacity: 1;
	}

	&.is-top-dialog & {
		box-shadow: $depth-3;

		/* move stack dialog or singe dialog to top of stack */
	}
}
