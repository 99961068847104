$color-check: url-color-legacy($color-grey44);

.b-refinement_link {
	appearance: none;
	background: transparent;
	border: none;
	color: $color-text;
	cursor: pointer;
	font-family: inherit;
	font-size: 16px;
	padding-left: rh(6);
	position: relative;
	text-align: left;

	&:hover {
		text-decoration: underline;
	}

	.b-card_refinement-list_item.js-selected > & {
		color: $color-grey44;
		font-weight: bold;

		.b-theme_rle & {
			font-weight: 500;
		}

		&:hover {
			text-decoration: none;
		}

		&::before {
			background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-check}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M12.647%201.858L6.302%207.675a1.282%201.282%200%200%201-1.702%200L1.325%204.672a1.04%201.04%200%200%201%200-1.561%201.282%201.282%200%200%201%201.702%200l2.425%202.223L10.945.298a1.282%201.282%200%200%201%201.702%200c.47.43.47%201.129%200%201.56z%27%2F%3E%3C%2Fsvg%3E') center 6px/14px no-repeat;
			content: '';
			display: block;
			height: 15px;
			left: 0;
			position: absolute;
			top: 2px;
			width: 15px;
		}
	}

	&.m-disabled {
		cursor: default;

		@include media(md-down) {
			pointer-events: initial;

			.b-refinement_link-label {
				color: $color-text;
			}
		}
	}

	&.m-return_icon {
		color: $color-brand;
		font-weight: bold;
		padding-left: rh(5);
		text-decoration: none;

		.b-theme_rle & {
			font-weight: 500;
		}

		&:hover {
			color: $color-brand52;
		}

		> svg {
			left: 0;
			position: absolute;
		}
	}

	.b-card_refinement-list_item.js-selected > &.m-return_icon { // in case when top category is selected category
		color: $color-brand;

		&:hover {
			color: $color-brand52;
		}

		&::before {
			display: none;
		}
	}
}
