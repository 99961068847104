.d-none {
	display: none;
}

.b-card_refinement {
	max-width: 100%;

	&-catHeader {
		margin-top: rh(5);

		.b-catHeader { /* stylelint-disable-line */
			font-weight: bold;
			font-size: 15px;
			text-align: left;
		}

		& + .b-card_refinement-list { // stylelint-disable-line
			& button.b-refinement_radio { // stylelint-disable-line
				padding-left: 32px;
			}
		}
	}

	&-header {
		appearance: none;
		background-color: transparent;
		border-bottom: 1px solid $color-brand94;
		border-left: none;
		border-right: none;
		border-top: 1px solid $color-brand94;
		color: $color-brand;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		padding: rh(5 4 5 4);
		text-align: left;
		width: 100%;

		@include media(lg-up) {
			padding-right: rh(0);
		}

		&:hover {
			color: $color-brand52;
		}

		&.filter-by {
			background-color: $color-brand;
			border-top: none;
			cursor: default;
			margin-top: 10px;
			padding: 0;

			@include media(sm) {
				margin-top: 0;
			}
		}
	}

	&:last-of-type {
		border-bottom: 1px solid $color-brand94;
	}

	&-header_desktop {
		position: relative;

		path {
			fill: $color-brand;
		}
	}

	&-header_down {
		transform: scale(1.5);
	}

	&-header_up {
		transform: scale(1.5);
		display: none;
	}

	&-header_title {
		display: block;
		font: bold 20px/1.2 $font-sans;
		text-transform: capitalize;

		.b-theme_rle & {
			font-weight: 500;
		}

		&.filter-by {
			color: $color-white;
			font: bold 16px/1.2 $font-sans;
			padding: rh(5 4 5 4);
		}
	}

	&-icon {
		margin-left: auto;
	}

	&-icon_line_1,
	&-icon_line_2 {
		transform-origin: center;
		transition: transform $motion-ease;
	}

	&-quantity {
		color: $color-grey65;
		vertical-align: baseline;
	}

	&-body {
		display: none;
		padding: rh(0 0 5 4);

		@include media(sm) {
			padding: rh(0 4 5 4);
		}
	}

	&-body_desktop {
		background: $color-white;
		border-radius: 0 0 5px 5px;
		border-top: 0;
		left: 0;
		padding: 20px;
		right: 0;
		width: 100%;
		z-index: 2;
	}

	&-sublist {
		margin-top: rh(5);
	}

	&-list { // stylelint-disable-line
		margin-top: rh(5);
		overflow-x: hidden;
		overflow-y: auto;

		&.displayCategoryName { /* stylelint-disable-line */
			margin-top: rh(3);
		}

		@include media(lg-up) { /* stylelint-disable-line */
			max-height: 365px;
		}

		&.m-clp {
			max-height: none;
			overflow: visible;
		}

		&::-webkit-scrollbar {
			width: 5px !important; // stylelint-disable-line
		}

		.b-card_refinement-sublist {
			padding-left: rh(4);
		}
	}

	&-list_item {
		margin: 1px 0 15px 2px;

		& &:last-child {
			margin-bottom: 0;
		}

		.b-card_refinement-list {
			margin-top: rh(5);
		}

		&.m-invisible {
			display: none;
		}
	}

	&-input {
		appearance: none;
		background-color: $color-white;
		border: 1px solid $color-brand97;
		border-radius: $global-radius;
		box-shadow: $depth-1;
		color: $color-text;
		font-family: $font-sans;
		font-size: 16px;
		margin-top: rh(5);
		padding: rh(2 4);
		width: 100%;

		.b-theme_hmv & {
			border: 1px solid var(--color-neutral-900);
		}

		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration,
		&::-webkit-inner-spin-button {
			appearance: none;
		}

		&::placeholder {
			.b-theme_hmv & {
				color: var(--color-neutral-0);
			}
		}

		&::-ms-clear {
			display: none;
		}

		&:focus,
		&:hover {
			border-color: $color-brand;
		}
	}

	&.m-refinement-color {
		.b-card_refinement-list {
			padding: rh(1);
		}
	}

	&.active { /* stylelint-disable-line */
		// stylelint-disable selector-class-pattern
		.b-card_refinement-header {
			color: $color-brand;
		}

		.b-card_refinement-icon_line_1 {
			transform: rotate(180deg);
		}

		.b-card_refinement-icon_line_2 {
			transform: rotate(270deg);
		}

		.b-card_refinement-body {
			border-bottom: 1px solid $color-brand94;
			display: block;

			&:last-of-type {
				border-bottom: 0;
			}
		}

		.b-card_refinement-header_up {
			display: block;
		}

		.b-card_refinement-header_down {
			display: none;
		}
	}
}
